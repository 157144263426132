//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({ firstWord: '¿Qué es una imagen ausente?' }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const readTime = 0.975
      const { loader } = this.$refs
      const loaderChildren = Array.from(loader.children)

      const gsap = this.$gsap

      const tl = gsap.timeline({
        delay: 0.1,
        paused: false,
        defaults: { duration: 0.65, ease: 'power1.out' },
      })

      tl.set(loaderChildren, {
        opacity: 0,
        filter: 'blur(10px)',
      })

      for (let i = 0; i < loaderChildren.length; i++) {
        const loaderChild = loaderChildren[i]

        if (i === 0) {
          tl.set(loaderChild, { opacity: 1, filter: 'blur(0px)' })
        } else tl.to(loaderChild, { opacity: 1, filter: 'blur(0px)' }, '-=0.125')

        tl.to(loaderChild, { opacity: 0, filter: 'blur(5px)' }, `+=${readTime}`)
      }

      tl.to(loader, {
        opacity: 0,
        onStart: () => {
          this.scrollTo(0)
        },
        onEnd: () => {
          this.$nuxt.$emit('show-shader')
          this.$nuxt.$emit('show-layout')
        },
      })
      tl.set(loader, { display: 'none' })
    },
    scrollTo(location) {
      const gsap = this.$gsap
      gsap.to(window, {
        scrollTo: {
          y: location,
        },
        duration: 0,
        ease: 'none',
      })
    },
  },
}
